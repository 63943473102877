import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import {
  DataGrid,
  GridToolbarContainer,
  GridRowModes,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";

import axios from "../../state/axios-instance";

import Header from "components/Header";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import Loading from "../../components/Loading";

import { ToastContainer, toast } from "react-toastify";

import * as XLSX from "xlsx";

const fields = [
  "Ten_HS",
  "Ten_PH",
  "SDT_PH",
  "Lop",
  "Chuong_Trinh",
  "Khu_Vuc",
  "Ngay_vao_hoc",
  "Entrance_fee",
  "Monthly_tuition",
  "Year_tuition",
  "Total_year_paid",
  "Entrance_payment",
  "Payments",
  "Note",
];

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    const currentDate = new Date().toISOString().split("T")[0];
    setRows((oldRows) => [
      ...oldRows,
      {
        _id: id,
        grade: "",
        day_admission: currentDate,
        curriculum: "",
        name: "",
        parent_name: "",
        parent_phone: "",
        school_area: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Edit recor
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

function generateYearOptions() {
  const currentYear = new Date().getFullYear();
  const startYear = 2022;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  }
  return years;
}

function formatDateForInput(input) {
  const date = new Date(input);
  const day = ("0" + date.getUTCDate()).slice(-2); // add 0 padding
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2); // add 0 padding
  const year = date.getUTCFullYear();
  return `${year}-${month}-${day}`;
}

function formatDateForDisplay(input) {
  const date = new Date(input);
  const day = ("0" + date.getUTCDate()).slice(-2); // add 0 padding
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2); // add 0 padding
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export default function Tuition() {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // ADD
  /* ************************************************************ */
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState();
  const [selectedYear, setSelectedYear] = useState(2023);
  const [isFetching, setIsFetching] = useState(false);

  // MODAL
  const [open, setOpen] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setCheckedValues({
      ...checkedValues,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleDownload = async () => {
    setIsFetching(true);
    try {
      const response = await axios.post(
        `/export/${selectedYear}`,
        checkedValues,
        {
          responseType: "arraybuffer",
        }
      );
      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: "array" });
      const excelBuffer = XLSX.write(workbook, {
        type: "buffer",
        bookType: "xlsx",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "students.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("Download success!", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      toast.error("Error loading data", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3500,
        hideProgressBar: false,
      });
    }
    setIsFetching(false);
    handleClose();
  };

  // Chon nam
  useEffect(() => {
    if (selectedYear) {
      setIsFetching(true);
      axios
        .get(`/tuition/${selectedYear}`)
        .then((res) => {
          console.log("RESPONSE:", res.data);
          const mappedData = res.data.map((item) => {
            const q1Payment = item.payments.find(
              (payment) =>
                payment.year === selectedYear && payment.quarter === 1
            );

            const q2Payment = item.payments.find(
              (payment) =>
                payment.year === selectedYear && payment.quarter === 2
            );

            const q3Payment = item.payments.find(
              (payment) =>
                payment.year === selectedYear && payment.quarter === 3
            );

            const q4Payment = item.payments.find(
              (payment) =>
                payment.year === selectedYear && payment.quarter === 4
            );

            const total2022 = item.yearly_payments.find(
              (payment) => payment.year === selectedYear
            );

            return {
              ...item,
              entrance_fee: item.tuition_fee.entrance_fee,
              entrance_payment_date: item.entrance_payment
                ? item.entrance_payment.payment_date
                : null,
              entrance_payment_amount: item.entrance_payment
                ? item.entrance_payment.payment_amount
                : null,
              monthly_tuition:
                item.tuition_fee.tuition_fee_details[0].monthly_tuition,
              amount_due_year:
                item.tuition_fee.tuition_fee_details[0].amount_due_year,
              Q1_payment_date: q1Payment ? q1Payment.payment_date : null,
              Q1_payment_amount: q1Payment ? q1Payment.payment_amount : null,
              Q2_payment_date: q2Payment ? q2Payment.payment_date : null,
              Q2_payment_amount: q2Payment ? q2Payment.payment_amount : null,
              Q3_payment_date: q3Payment ? q3Payment.payment_date : null,
              Q3_payment_amount: q3Payment ? q3Payment.payment_amount : null,
              Q4_payment_date: q4Payment ? q4Payment.payment_date : null,
              Q4_payment_amount: q4Payment ? q4Payment.payment_amount : null,
              total_paid_year: total2022.total_amount_paid,
            };
          });

          setRows(mappedData);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  }, [selectedYear]);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row._id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (newRow.isNew) {
    } else {
      const data = {
        _id: newRow._id,
        name: newRow.name,
        day_admission: newRow.day_admission,
        entrance_fee: newRow.entrance_fee,
        entrance_payment_date: newRow.entrance_payment_date,
        entrance_payment_amount: newRow.entrance_payment_amount,
        monthly_tuition: newRow.monthly_tuition,
        Q1_payment_date: newRow.Q1_payment_date,
        Q2_payment_date: newRow.Q2_payment_date,
        Q3_payment_date: newRow.Q3_payment_date,
        Q4_payment_date: newRow.Q4_payment_date,
        Q1_payment_amount: newRow.Q1_payment_amount,
        Q2_payment_amount: newRow.Q2_payment_amount,
        Q3_payment_amount: newRow.Q3_payment_amount,
        Q4_payment_amount: newRow.Q4_payment_amount,
      };
      axios
        .put(`/tuition/${selectedYear}`, data)
        .then((res) => {})
        .catch((err) => console.log(err));
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /* ************************************************************************* */

  const columns = [
    {
      field: "name",
      headerName: "Tên HS",
      width: 300,
      editable: false,
    },
    {
      field: "day_admission",
      headerName: "Ngày vào học",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return formatDateForDisplay(params.value);
      },
    },
    {
      field: "entrance_fee",
      headerName: "Phí nhập học",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "entrance_payment_date",
      headerName: "Ngày đóng",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value ? formatDateForDisplay(params.value) : "";
      },
      renderEditCell: (params) => {
        const { id, field } = params;
        const value = params.row[field]; // Use dynamic field instead of hardcoded "Q1_payment_date"
        return (
          <TextField
            type="date" // Set the input type to "date"
            value={value ? formatDateForInput(value) : ""}
            onChange={(e) => {
              const dateValue = e.target.value; // The value will be in "yyyy-MM-dd" format
              params.api.setEditCellValue({ id, field, value: dateValue }, e);
              params.api.commitCellChange({ id, field });
              params.api.setCellMode(id, field, "view");
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
    },
    {
      field: "entrance_payment_amount",
      headerName: "Số tiền đóng phí nhập học",
      width: 200,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "monthly_tuition",
      headerName: "Học phí tháng",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "amount_due_year",
      headerName: selectedYear
        ? `Tổng học phí ${selectedYear}`
        : "Tổng học phí 2022",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "total_paid_year",
      headerName: "Tổng tiền năm đã đóng",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "Q1_payment_date",
      headerName: "Ngày đóng Q1",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value ? formatDateForDisplay(params.value) : "";
      },
      renderEditCell: (params) => {
        const { id, field } = params;
        const value = params.row[field]; // Use dynamic field instead of hardcoded "Q1_payment_date"
        return (
          <TextField
            type="date" // Set the input type to "date"
            value={value ? formatDateForInput(value) : ""}
            onChange={(e) => {
              const dateValue = e.target.value; // The value will be in "yyyy-MM-dd" format
              params.api.setEditCellValue({ id, field, value: dateValue }, e);
              params.api.commitCellChange({ id, field });
              params.api.setCellMode(id, field, "view");
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
    },
    {
      field: "Q1_payment_amount",
      headerName: "Số tiền đóng Q1",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "Q2_payment_date",
      headerName: "Ngày đóng Q2",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value ? formatDateForDisplay(params.value) : "";
      },
      renderEditCell: (params) => {
        const { id, field } = params;
        const value = params.row[field]; // Use dynamic field instead of hardcoded "Q1_payment_date"
        return (
          <TextField
            type="date" // Set the input type to "date"
            value={value ? formatDateForInput(value) : ""}
            onChange={(e) => {
              const dateValue = e.target.value; // The value will be in "yyyy-MM-dd" format
              params.api.setEditCellValue({ id, field, value: dateValue }, e);
              params.api.commitCellChange({ id, field });
              params.api.setCellMode(id, field, "view");
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
    },
    {
      field: "Q2_payment_amount",
      headerName: "Số tiền đóng Q2",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "Q3_payment_date",
      headerName: "Ngày đóng Q3",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value ? formatDateForDisplay(params.value) : "";
      },
      renderEditCell: (params) => {
        const { id, field } = params;
        const value = params.row[field]; // Use dynamic field instead of hardcoded "Q1_payment_date"
        return (
          <TextField
            type="date" // Set the input type to "date"
            value={value ? formatDateForInput(value) : ""}
            onChange={(e) => {
              const dateValue = e.target.value; // The value will be in "yyyy-MM-dd" format
              params.api.setEditCellValue({ id, field, value: dateValue }, e);
              params.api.commitCellChange({ id, field });
              params.api.setCellMode(id, field, "view");
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
    },
    {
      field: "Q3_payment_amount",
      headerName: "Số tiền đóng Q3",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },
    {
      field: "Q4_payment_date",
      headerName: "Ngày đóng Q4",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value ? formatDateForDisplay(params.value) : "";
      },
      renderEditCell: (params) => {
        const { id, field } = params;
        const value = params.row[field]; // Use dynamic field instead of hardcoded "Q1_payment_date"
        return (
          <TextField
            type="date" // Set the input type to "date"
            value={value ? formatDateForInput(value) : ""}
            onChange={(e) => {
              const dateValue = e.target.value; // The value will be in "yyyy-MM-dd" format
              params.api.setEditCellValue({ id, field, value: dateValue }, e);
              params.api.commitCellChange({ id, field });
              params.api.setCellMode(id, field, "view");
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
    },
    {
      field: "Q4_payment_amount",
      headerName: "Số tiền đóng Q4",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return params.value
          ? params.value.toLocaleString("it-IT", {
              style: "currency",
              currency: "VND",
            })
          : "";
      },
    },

    // Add
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={{}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Navbar
          user={{}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
        <ToastContainer />
        <Box m="0rem 2.5rem">
          <Header title="LIST OF STUDENTS" />
          <Box
            mt="20px"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <Box mr={2} width="100px">
                {/* <Button variant="contained" endIcon={<CalendarMonthIcon />}>
                  Year
                </Button> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Years</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedYear}
                    onChange={handleChangeYear}
                  >
                    {/* <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem> */}
                    {generateYearOptions()}
                  </Select>
                </FormControl>
              </Box>

              {/* LOADING BUTTON */}
              {isFetching && <Loading />}

              <Box mr={2}>
                <Button
                  variant="contained"
                  endIcon={<CalendarMonthIcon />}
                  onClick={handleClickOpen}
                >
                  Export Excel
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Choose Fields to Export</DialogTitle>
                  <DialogContent>
                    {fields.map((field) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedValues[field] || false}
                            onChange={handleCheckboxChange}
                            name={field}
                          />
                        }
                        label={field}
                        key={field}
                      />
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDownload}>Download</Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
            <div style={{ width: "100%", overflowX: "auto", height: "100%" }}>
              <DataGrid
                rows={rows || []}
                columns={columns}
                editMode="row"
                loading={!rows || isFetching}
                getRowId={(row) => row._id}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
