import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import moment from "moment";

import PropTypes from "prop-types";
import {
  DataGrid,
  GridToolbarContainer,
  GridRowModes,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";

import axios from "../../state/axios-instance";

import Header from "components/Header";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";

import { ToastContainer, toast } from "react-toastify";

/* Add button */

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        _id: id,
        grade: "",
        day_admission: new Date(),
        curriculum: "",
        name: "",
        parent_name: "",
        parent_phone: "",
        school_area: "",
        note: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add student
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

function parseDate(dateStr) {
  const momentDate = moment.utc(dateStr, "YYYY-MM-DD");
  return momentDate.isValid() ? momentDate.local().toDate() : null;
}

export default function Students() {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // ADD
  /* ************************************************************ */
  const [rowModesModel, setRowModesModel] = useState({});
  const [rows, setRows] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    axios
      .get("/student/students")
      .then((res) => {
        setRows(res.data);
      })
      .catch((err) => {
        toast.error("Error loading data", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 3500,
          hideProgressBar: false,
        });
      });
    setIsFetching(false);
  }, []);

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    axios.delete(`/student/delete/${id}`).then(() => {
      setRows(rows.filter((row) => row._id !== id));
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row._id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    const dateWithoutTime = new Date(newRow.day_admission)
      .toISOString()
      .split("T")[0];
    const data = {
      name: newRow.name,
      grade: parseInt(newRow.grade),
      curriculum: newRow.curriculum,
      day_admission: dateWithoutTime,
      parent_name: newRow.parent_name,
      parent_phone: newRow.parent_phone,
      school_area: newRow.school_area,
      note: newRow.note,
    };

    if (newRow.isNew) {
      axios
        .post("/student/add", data)
        .then((res) => {
          setRows(rows.filter((row) => row._id !== newRow._id));
          setRows((oldRows) => [...oldRows, res.data]);
        })
        .catch((err) => console.log(err));
    } else {
      axios.put(`/student/edit/${newRow._id}`, data).then((res) => {
        setRows(rows.map((row) => (row._id === res.data._id ? res.data : row)));
      });
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /* ************************************************************************* */

  const columns = [
    {
      field: "name",
      headerName: "Tên HS",
      width: 300,
      editable: true,
    },
    {
      field: "parent_phone",
      headerName: "SĐT PH",
      width: 200,
      editable: true,
    },
    {
      field: "parent_name",
      headerName: "Tên PH",
      width: 250,
      editable: true,
    },
    {
      field: "grade",
      headerName: "Lớp",
      width: 50,
      editable: true,
    },
    {
      field: "curriculum",
      headerName: "CT",
      width: 50,
      editable: true,
    },
    // {
    //   field: "day_admission",
    //   headerName: "Ngày vào học",
    //   width: 150,
    //   editable: true,
    //   // renderCell: (params) => {
    //   //   return formatDate(params.value);
    //   // },
    //   renderCell: (params) => {
    //     return formatDate(params.value);
    //   },
    //   renderEditCell: (params) => {
    //     const { id, field } = params;
    //     const value = params.row[field];
    //     return (
    //       <TextField
    //         value={value ? formatDate(value) : ""}
    //         onChange={(e) => {
    //           params.api.setEditCellValue(
    //             { id, field, value: parseDate(e.target.value) },
    //             e
    //           );
    //           params.api.commitCellChange({ id, field });
    //           params.api.setCellMode(id, field, "view");
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      field: "day_admission",
      headerName: "Ngày vào học",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return formatDate(params.value);
      },
      renderEditCell: (params) => {
        const { id, field } = params;
        const value = params.row[field];
        return (
          <TextField
            type="date"
            value={value ? moment(value).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              params.api.setEditCellValue(
                { id, field, value: parseDate(e.target.value) },
                e
              );
              params.api.commitCellChange({ id, field });
              params.api.setCellMode(id, field, "view");
            }}
          />
        );
      },
    },
    {
      field: "school_area",
      headerName: "Khu vực",
      width: 100,
      editable: true,
    },
    {
      field: "note",
      headerName: "Ghi chú",
      width: 250,
      editable: true,
    },

    // Add
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={{}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Navbar
          user={{}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
        <Box m="0rem 2.5rem">
          <Header title="LIST OF STUDENTS" />
          <ToastContainer />
          <Box
            mt="20px"
            height="80vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={rows || []}
              columns={columns}
              editMode="row"
              loading={isFetching || !rows}
              getRowId={(row) => row._id}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
