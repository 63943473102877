import React from "react";
import { MoonLoader} from "react-spinners";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-overlay">
        <MoonLoader color={"#ffd166"} />
      </div>
    </div>
  );
};

export default Loading;
